/* ==========================================================================
   #LISTING (News, Events, etc)
   ========================================================================== */

.c-listing__entry {
  &--above {
    margin: rem_calc(20 0);
  }

  &--within {
    margin: rem_calc(50 0);
  }
}

.c-listing__item {
  border-bottom: rem-calc(2) solid $color-grey-light-medium;
  margin-bottom: $global-spacing;
  padding-bottom: $global-spacing;
  font-size: rem_calc(16);

  @include bp-large('max') {
    text-align: center;

    .c-btn {
      float: none !important;
    }

    img,
    .c-listing__title,
    p {
      max-width: rem_calc(420);
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    font-size: rem_calc(16);
    color: $color-black;
  }
}

.c-listing__title {
  margin-bottom: rem_calc(30);
  font-weight: 700;
  color: $color-grey-medium;
  font-size: 1.25rem;

  @include bp-large('max') {
    margin-bottom: rem_calc(20);
  }

  &--right {
    margin-top: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.c-listing__link {
  //color: $color-grey;
  text-decoration: none;

  &:hover {
    //color: $color-link;
    text-decoration: none;
  }
}
