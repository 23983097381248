.c-mobile-nav {
  position: fixed;
  top: 0;
  right: - rem_calc(250);
  bottom: 0;
  display: none;
  width: rem_calc(250);
  padding: rem_calc(10 0 20);
  text-align: left;
  z-index: 9;
  overflow-y: auto;
  transition: $global-transition;
  box-shadow: -3px 0 6px -1px rgba(0, 0, 0, 0);

  @include bp-large('max') {
    display: block;
  }

  @include bp-small-medium('max') {
    width: rem_calc(200);
    right: - rem_calc(200);
  }

  &--open {
    right: 0;
    box-shadow: -3px 0 6px -1px rgba(0, 0, 0, 0.2);
  }

  &__items,
  &__dropdown {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__item,
  &__subitem {
    margin: rem_calc(0);
  }

  &__link,
  &__sublink {
    display: block;
    width: 100%;
    padding: rem_calc(10);
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__link {
    .c-mobile-nav--cap-items & {
      text-transform: uppercase;
    }
  }

  &__sublink {
    .c-mobile-nav--cap-subitems & {
      text-transform: uppercase;
    }
  }

  &__dropdown {
    .c-mobile-nav__sublink {
      padding-left: rem_calc(20);

      .c-mobile-nav__dropdown & {
        padding-left: rem_calc(30);
      }
    }

    & & & .c-mobile-nav__sublink {
      padding-left: rem_calc(40);
    }
  }

  &__close {
    margin: rem_calc(10);
  }
}

.c-mobile-nav__trigger,
.c-main-nav__trigger,
.c-mobile-nav__close {
  display: block;
  width: rem_calc(30);
  height: 20px;
  margin-right: rem_calc(5);
  border: 0;
  border-top-color: $color-theme;
  //border-top: rem-calc(4) solid;
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s ease;
  //outline: 0;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: rem-calc(4) solid;
    border-top-color: $color-theme;
    transform: translateY(rem-calc(3));
    transition: transform 0.1s ease;
    //outline: 0;
  }

  &::after {
    transform: translateY(rem-calc(14));
  }

  &--open,
  .c-main-nav--open & {
    transform: rotate(45deg) translateY(5px);
    border: none;

    &::after {
      transform: rotate(-90deg) translateX(-3px);
    }
  }
}

.c-main-nav__trigger {
  width: rem_calc(40);

  &::after,
  &::before {
    border-top-width: rem-calc(5);
  }
}
