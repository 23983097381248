.c-content__restricted {
  form {
    max-width: rem_calc(500);
    margin: auto;
    text-align: center;

    p:last-child {
      text-align: left;
    }

    input[type=submit] {
      @extend .c-btn;
      @extend .c-btn--primary;
      @extend .c-btn--small;
      display: block;
      margin: auto;
    }
  }
}
