/* ==========================================================================
   #FORM ELEMENTS
   ========================================================================== */

select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  appearance: none;

  width: 100%;
  height: rem_calc(70);
  margin-bottom: rem_calc(5);
  padding: rem-calc(15);
  border: rem-calc(3) solid $color-theme;
  color: $color-theme;
  vertical-align: top;
  font-size: rem_calc(18);
  font-weight: 600;
  background-color: $color-white;
  box-shadow: none;
  //outline: none;
  transition: $global-transition;

  @include bp-xxlarge('max') {
    height: rem_calc(66);
  }

  @include bp-large('max') {
    height: rem_calc(62);
  }

  @include bp-medium('max') {
    height: rem_calc(50);
    padding: rem-calc(5 10);
    font-size: rem_calc(16);
    border-width: rem-calc(2);
  }

  &::placeholder {
    color: $color-theme;
    transition: $global-transition;
  }

  &:hover,
  &:focus {
    border: rem-calc(3) solid $color-theme-dark;

    @include bp-medium('max') {
      border-width: rem-calc(2);
    }
  }
}

[type=checkbox],
[type=radio] {
  margin-right: rem-calc(15);
}

select {
  opacity: 0;
}

textarea {
  height: auto;
}

label {
  display: block;
  color: $color-theme;
  line-height: 1.6em;
}

/* Chrome yellow fields fix */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: 0 0 0 1000px #fff inset !important;
}
