/* ==========================================================================
   #SIDEBAR
   ========================================================================== */

.c-sidebar {
  &--right {
    @include bp-large {
      order: 99;
    }
  }

  &__custom-content {
    max-width: rem_calc($sidebar_max_width);
    width: 100%;
    padding-right: rem_calc(20);
    padding-left: 0;

    .c-sidebar--4 & {
      max-width: rem_calc($sidebar_max_width_lg);
    }

    .c-sidebar--right &,
    .c-blocks__sidebar--right & {
      padding-left: rem_calc(20);
      padding-right: 0;
    }

    @include bp-large('max') {
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &--below {
      padding-top: rem_calc(30);
    }

    &--above {
      padding-bottom: rem_calc(20);
    }
  }
}

.c-sidebar__widget {
  max-width: rem_calc($sidebar_max_width);
  width: 100%;
  padding-right: rem_calc(20);
  padding-left: 0;
  margin-bottom: rem-calc(10);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  .c-sidebar--right &,
  .c-blocks__sidebar--right & {
    padding-left: rem_calc(20);
    padding-right: 0;
  }

  .c-footer__sidebar & {
    max-width: none;
  }

  @include bp-large('max') {
    margin: 0 auto;
    padding-right: 0;
  }
}

.c-page-block__sidebar {
  position: relative;
  z-index: 2;
}

.c-sidebar__header {
  display: inline-block;
  padding: rem_calc(10 0);
  margin: rem_calc(10 10 25);
  border-bottom: rem_calc(4) solid $color-theme;
  color: $color-grey;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.widget_media_audio {
  overflow: hidden;
}

.widget_nav_menu,
.widget_pages,
.c-side-nav,
.widget_recent_entries {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    ul {
      padding: 0 0 10px 0;
    }

    li {
      margin: 0;

      .c-side-nav__sublink {
        padding: rem_calc(10 35);

        &--level2 {
          padding: rem_calc(10 45);
        }
      }

      a {
        display: block;
        padding: rem_calc(10 25);
        margin-bottom: - rem_calc(1);
        border: rem_calc(1) solid $color-border;
        color: $color-grey-dark;
        text-decoration: none;
        transition: $global-transition;

        /* stylelint-disable selector-no-qualifying-type */

        &:hover,
        &.c-side-nav__link--current,
        &.c-side-nav__sublink--current {
          background-color: $color-border;
        }

        /* stylelint-enable selector-no-qualifying-type */
      }

      /* stylelint-disable selector-no-qualifying-type */

      &.current-menu-item > a,
      &.current_page_item > a,
      .c-side-nav__link--current > a,
      .c-side-nav__link--current:hover > a {
        background-color: $color-border;
      }

      .c-side-nav__link--month > a {
        padding: rem_calc(10 25 10 35);
      }

      /* stylelint-enable selector-no-qualifying-type */

      .c-side-nav__icon {
        position: relative;
        top: - rem_calc(2);
        display: inline-block;
        vertical-align: middle;
        margin-right: rem-calc(8);
        width: rem-calc(24);
        height: rem-calc(24);
      }

      .post-date {
        display: none;
      }
    }
  }
}

.widget_rss {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin: 0;
      display: block;
      padding: rem_calc(10 25);
      margin-bottom: - rem_calc(1);
      border: rem_calc(1) solid $color-border;

      a {
        display: block;
        color: $color-grey-dark;
        text-decoration: none;
        transition: $global-transition;

        &:hover {
          color: $color-link;
        }
      }
    }

    .rss-date {
      display: block;
      font-size: rem_calc(16);
      padding: rem_calc(5 0);
      font-style: italic;
      font-weight: 700;
    }

    .rssSummary {
      font-size: rem_calc(14);
    }
  }
}

.widget__content-box-inner {
  position: relative;
  max-width: rem_calc($sidebar_max_width);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  //margin: rem_calc(10) auto;

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  @include bp-large('max') {
    max-width: rem_calc(420);
  }

  @include bp-medium('max') {
    max-width: rem_calc(380);
  }

  a {
    color: inherit !important;
    text-decoration: none;
  }
}

.widget__content-box-inner-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: $global-transition;

  .widget__content-box-inner:hover & {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.widget__content-box-content {
  position: relative;
  bottom: 0;
  height: rem_calc(270);
  z-index: 2;
  overflow: hidden;

  * {
    color: $color-white;
  }

  @include bp-large('max') {
    height: rem_calc(210);
  }

  p,
  h3 {
    position: absolute;
    left: rem_calc(30);
    right: rem_calc(30);
    display: block;
    transition: all 0.3s ease-in-out;
    transition-delay: 0s;

    @include bp-xxlarge('max') {
      left: rem_calc(20);
      right: rem_calc(20);
    }

    @include bp-xlarge('max') {
      left: rem_calc(15);
      right: rem_calc(15);
    }
  }

  p {
    height: rem_calc(120);
    margin-bottom: rem_calc(20);
    overflow: hidden;
    bottom: 0;

    @include bp-large('max') {
      height: rem_calc(90);
      margin-bottom: rem_calc(10);
    }

    @include bp-small('max') {
      height: rem_calc(110);
    }
  }

  h3 {
    top: rem_calc(20);
    bottom: 50%;
    line-height: 2rem;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;

    @include bp-xlarge('max') {
      top: rem_calc(10);
    }

    @include bp-large('max') {
      bottom: 53%;
      line-height: 1.5rem;
    }
  }

  &--hover {
    p {
      @include bp-medium {
        bottom: rem_calc(-150);

        .widget__content-box-inner:hover & {
          bottom: 0;
          transition-delay: 0.1s;
        }
      }
    }

    h3 {
      @include bp-medium {
        top: auto;
        bottom: 0;
      }

      .widget__content-box-inner:hover & {
        @include bp-medium {
          bottom: 53%;
          transition-delay: 0s;
        }

        @include bp-large {
          bottom: 50%;
        }
      }
    }
  }
}

.widget__html-content,
.widget_text .textwidget {
  padding: rem_calc(10 25);

  .c-btn {
    margin-bottom: rem_calc(20);
  }

  ul {
    li {
      list-style: none;

      &:before {
        display: inline-block;
        margin: rem_calc(0 5 0 0);
        padding: 0;
        content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='10'><circle cx='5' cy='5' r='4' stroke='black' stroke-width='1' fill='#ffffff' fill-opacity='0' /></svg>"); /* stylelint-disable-line string-quotes */
        width: rem_calc(12);
      }

      a {
        padding: rem_calc(5 0);
        display: inline-block;
      }
    }
  }
}

.widget_x5_widget_upcoming_events {
  .c-widget__events-item {
    border: rem_calc(1) solid $color-border;
    text-align: center;
    display: block;
    text-decoration: none;
    color: $color-black;
    transition: $global-transition;

    &:hover {
      background-color: $color-border;
    }
  }

  .c-widget__events-image {
    display: block;
    margin: 0 auto;
  }

  p {
    padding: 0 rem_calc(10);
    margin: rem_calc(10) 0;
  }

  .c-widget__events-start-date,
  .c-widget__events-location {
    display: inline-block;
    padding: 0;
  }

  .c-widget__events-start-date,
  .c-widget__events-location,
  .c-widget__events-speaker {
    font-size: rem-calc(16);
  }
}

.widget__newsletter {
  padding: rem_calc(10 10);
  background-color: $color-grey-lighter;

  p,
  a {
    display: block;
    margin: 0;
    padding: rem_calc(10);
  }

  &-link {
    color: $color-grey-dark;
    font-size: rem-calc(15);
    line-height: normal;
    text-decoration: none;

    &:hover .widget__newsletter-label {
      text-decoration: underline;
      color: $color-grey-dark;
    }

    svg {
      display: inline-block;
      margin-right: rem-calc(10);
      vertical-align: top;
    }
  }
}

.c-footer__sidebar {
  padding: rem_calc(30 26);

  @include bp-large('max') {
    padding: rem_calc(10 26);
  }

  &-column {
    padding: rem_calc(0 26 10);

    @include bp-xlarge-medium('max') {
      padding: rem_calc(0 15 10);
    }

    @include bp-xlarge('max') {
      padding: rem_calc(0 10 10);
    }

    @include bp-large('max') {
      padding: rem_calc(0 0 10);
    }

    &:nth-child(1) {
      padding-right: rem_calc(40);
      padding-left: rem_calc(13);

      @include bp-xlarge-medium('max') {
        padding-right: rem_calc(30);
      }

      @include bp-xlarge('max') {
        padding-right: rem_calc(20);
      }

      @include bp-large('max') {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &:nth-child(3) {
      padding-right: rem_calc(13);
      padding-left: rem_calc(40);

      @include bp-xlarge-medium('max') {
        padding-left: rem_calc(30);
      }

      @include bp-xlarge('max') {
        padding-left: rem_calc(20);
      }

      @include bp-large('max') {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .c-sidebar__widget {
    max-width: none;
    margin: auto;

    @include bp-large('max') {
      max-width: rem_calc($sidebar_max_width);

      .c-sidebar--4 & {
        max-width: rem_calc($sidebar_max_width_lg);
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
