///* ========================================================================
//   #ALERTS
//   ======================================================================== */

$alert-padding: rem-calc(10) !default;
$alert-font-size: rem-calc(14) !default;
$alert-margin-bottom: rem-calc(20) !default;
$alert-border-right: 1px solid !default;
$alert-border-left: 5px solid !default;
$alert-color-info: $color-info !default;
$alert-color-error: $color-error !default;
$alert-color-success: $color-success !default;
$alert-bg-color-lighten-threshold: 40% !default;
$alert-color-darken-threshold: 15% !default;

@mixin alert-base() {
  padding: $alert-padding;
  font-size: $alert-font-size;
  margin-bottom: $alert-margin-bottom;
  border-right: $alert-border-right;
  border-left: $alert-border-left;
}

@mixin alert-styling($color: $color-black) {
  border-left-color: $color;
  border-right-color: $color;
  background-color: lighten($color, $alert-bg-color-lighten-threshold);
  color: darken($color, $alert-color-darken-threshold);
}

@mixin alert-error() {
  @include alert-styling($alert-color-error);
}

@mixin alert-success() {
  @include alert-styling($alert-color-success);
}

@mixin alert-info() {
  @include alert-styling($alert-color-info);
}
