.c-page__hero {
  position: relative;
  margin: 0 auto;
  background-size: cover;

  &--container {
    max-width: $global-max-width;
  }

  .o-layout {
    position: relative;
    z-index: 2;

    &__item {
      box-sizing: border-box;
      padding-top: rem_calc(50);
      padding-bottom: rem_calc(50);

      @include bp-large('max') {
        padding-top: rem_calc(30);
        padding-bottom: rem_calc(30);
      }

      @include bp-medium('max') {
        padding-top: rem_calc(20);
        padding-bottom: rem_calc(20);
      }
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-scrolldown {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: rem_calc(50);
    width: rem_calc(40);
    height: rem_calc(40);
    cursor: pointer;

    @include bp-large('max') {
      bottom: rem_calc(30);
      width: rem_calc(30);
      height: rem_calc(30);
    }

    @include bp-medium('max') {
      bottom: rem_calc(20);
      width: rem_calc(25);
      height: rem_calc(25);
    }
  }
}

@for $i from 2 through 5 {
  div[class*='c-page__hero--h-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
    @media (max-width: $bp-large) {
      @if $i > 2 {
        min-height: rem-calc(200) !important;
      }

      @if $i > 3 {
        min-height: rem-calc(300) !important;
      }
    }

    @media (max-width: $bp-large) {
      @if $i > 2 {
        min-height: rem-calc(100) !important;
      }

      @if $i > 3 {
        min-height: rem-calc(200) !important;
      }
    }
  }
}
