/* stylelint-disable selector-max-id */

#baguetteBox-overlay {
  background-color: rgba(150, 176, 238, 0.9) !important;

  .full-image {
    padding: rem_calc(25);

    @include bp-medium('max') {
      padding: rem_calc(10);
    }

    figcaption {
      padding: rem_calc(5);
      color: #eee;
    }
  }

  .baguetteBox-button {
    background-color: rgba(255, 255, 255, 0) !important;

    svg {
      polyline,
      g {
        stroke: $color-theme !important;
        stroke-width: rem_calc(8) !important;
      }

      g {
        stroke-width: rem_calc(6) !important;
      }
    }
  }
}

/* stylelint-enable */
