/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  font-size: rem_calc(22);
  font-weight: 400;
  text-align: center; /* [4] */
  margin: rem_calc(10 0); /* [4] */
  cursor: pointer; /* [5] */
  padding: rem-calc(12 45);
  transition: $global-transition;
  border: 1px solid $color-link-alt;
  text-decoration: none;
  //outline: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &--expand {
    display: block;
  }

  &--full-width {
    width: 100%;
  }

  &--radius {
    border-radius: $global-radius;
  }

  &__icon {
    fill: currentColor;
  }

  .c-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: rem-calc(30);
    height: rem-calc(20);
    transition: $global-transition;
  }

  @include bp-xxlarge('max') {
    padding: rem-calc(10 40);
    font-size: rem_calc(20);
  }

  @include bp-xlarge('max') {
    padding: rem-calc(8 35);
    font-size: rem_calc(18);
    border-width: 2px;
  }

  @include bp-medium('max') {
    padding: rem-calc(6 30);
    font-size: rem_calc(17);
  }

  &--small {
    padding: rem-calc(8 30);
    font-size: rem_calc(20);

    @include bp-xxlarge('max') {
      padding: rem-calc(7 25); // 8 30
      font-size: rem_calc(18);
    }

    .c-icon {
      width: rem-calc(25);
      height: rem-calc(15);
    }
  }

  &--medium {
    padding: rem-calc(15 60);

    @include bp-xxlarge('max') {
      padding: rem-calc(13 55);
    }

    @include bp-xlarge('max') {
      padding: rem-calc(11 50);
    }

    @include bp-medium('max') {
      padding: rem-calc(10 40);
    }

    .c-icon {
      width: rem-calc(35);
      height: rem-calc(25);
    }
  }

  &--large {
    padding: rem-calc(20 75);

    @include bp-xxlarge('max') {
      padding: rem-calc(16 70);
    }

    @include bp-xlarge('max') {
      padding: rem-calc(14 65);
    }

    @include bp-medium('max') {
      padding: rem-calc(12 55);
    }

    .c-icon {
      width: rem-calc(40);
      height: rem-calc(30);
    }
  }
}


/* Style variants
   ========================================================================== */

// parameters - $bg-color, $bg-color-hover, $color, $color-hover, $border, $border-hover: $border

.c-btn--primary {
  @include btn-style(transparent, $color-theme-alt, $color-theme-alt, $color-white, rem-calc(3) solid $color-theme-alt);
}

.c-btn--primary-filled {
  @include btn-style($color-theme-alt, transparent, $color-white, $color-theme-alt, rem-calc(3) solid $color-theme-alt);
}

.c-btn--secondary {
  @include btn-style(transparent, $color-theme, $color-theme, $color-white, rem-calc(3) solid $color-theme);
}

.c-btn--secondary-filled {
  @include btn-style($color-theme, transparent, $color-white, $color-theme, rem-calc(3) solid $color-theme);
}

.c-btn--link {
  @include btn-style(transparent, $color-link-alt, $color-link-alt, $color-white, rem-calc(3) solid $color-link-alt);
}

.c-btn--link-filled {
  @include btn-style($color-link-alt, transparent, $color-white, $color-link-alt, rem-calc(3) solid $color-link-alt);
}

.c-btn--link-underline {
  @include btn-style(transparent, transparent, $color-grey-dark, $color-link, none);
  padding-left: 0;
  padding-right: 0;
  border-bottom: rem-calc(3) solid $color-theme;

  &:hover,
  &:active,
  &:focus {
    border-bottom: rem-calc(3) solid $color-link;
  }
}

.c-btn--primary-light {
  @include btn-style($color-theme-alt-light, $color-theme-alt, $color-white, $color-white, rem-calc(3) solid $color-theme-alt-light, rem-calc(3) solid $color-theme-alt);
}

.c-btn--secondary-light {
  @include btn-style($color-theme-light, $color-theme, $color-white, $color-white, rem-calc(3) solid $color-theme-light, rem-calc(3) solid $color-theme);
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: rem-calc(5 15);
  border: none !important;
}

.c-btn--arrow-primary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme-alt;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme-alt;

    .c-icon {
      transform: translateX(5px);
    }
  }
}

.c-btn--arrow-secondary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme;

    .c-icon {
      transform: translateX(5px);
    }
  }
}
