.c-project {
  max-width: none !important;
  margin: 0 auto 50px !important;
  padding-bottom: rem_calc(10);
  border-bottom: rem_calc(2) solid #ddd;

  &:last-child {
    border-bottom: none;
  }

  .o-layout__item {
    padding: 0;

    &.u-padding-left {
      padding-left: rem_calc(25);

      @include bp-medium('max') { /* stylelint-disable-line max-nesting-depth */
        padding-left: 0;
      }
    }
  }

  @include bp-large('max') {
    text-align: center;

    .c-btn {
      float: none !important;
    }
  }

  @include bp-medium('max') {
    max-width: 250px !important;
    margin: 0 auto 30px !important;
  }
}

.c-project__title {
  margin-bottom: rem_calc(30);

  @include bp-large('max') {
    margin-bottom: rem_calc(20);
  }

  a {
    color: inherit;

    &:hover {
      color: $color-link;
    }
  }
}

.c-project__category {
  color: $color-grey-medium-light;
  margin-bottom: rem_calc(40);
  text-align: left;

  @include bp-medium('max') {
    text-align: center;
  }
}

.c-project__lead {
  a {
    color: $color-link;
  }

  @include bp-large('max') {
    margin-top: rem_calc(15);
  }
}

.c-project__link {
  display: inline-block;
  margin-top: rem_calc(25);
  padding: rem_calc(0 50 50 0);

  @include bp-medium('max') {
    padding: rem_calc(0 30 30 0);
  }
}

.c-project__gallery-image {
  p {
    display: block;
    padding: rem_calc(5);
    font-weight: 600;
    border: 1px solid $color-grey-light-medium;
  }
}

.c-project__video {
  &-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
