.flatpickr {
  &-calendar {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
    border-radius: rem-calc(5);
    width: rem-calc(250);

    &.open,
    &.inline {
      max-height: rem-calc(235);

      &.hasTime {
        max-height: rem-calc(250);
      }
    }

    .dayContainer,
    .flatpickr-weeks {
      padding: 1px 0 0 0;
    }

    .dayContainer {
      padding: 0;
      // outline: 0;
      text-align: left;
      width: rem-calc(250);
      min-width: rem-calc(250);
      max-width: rem-calc(250);
      max-height: rem-calc(180);
    }
  }

  &-month {
    height: rem-calc(28);
  }

  &-prev-month,
  &-next-month {
    .flatpickr-months & {
      line-height: rem-calc(18);
      height: rem-calc(28);
      padding: rem-calc(7) calc(3.57% - 1.5px);
    }

    svg {
      width: rem-calc(14);
    }

    &:hover svg {
      fill: $color-theme !important;
    }
  }

  &-current-month {
    font-size: rem-calc(16);
    width: 75%;
    left: 12.5%;
    padding: rem-calc(6) 0 0 0;
    line-height: 1;
    height: rem-calc(28);

    input.cur-year { /* stylelint-disable-line selector-no-qualifying-type */
      padding: 0 0 0 rem-calc(5);
      margin: 0;
      display: inline-block;
      font-weight: 300;
      line-height: inherit;
      height: initial;
      border: none;
    }
  }

  &-weekdays {
    width: 100%;
    height: rem-calc(28);
    font-size: rem-calc(13);
  }

  &-days {
    width: rem-calc(250);
  }

  &-day {
    width: 14.2857143%;
    flex-basis: 14.2857143%;
    max-width: rem-calc(35);
    height: rem-calc(35);
    line-height: rem-calc(35);
    margin: 0;

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
      background: $color-grey-lighter;
      border-color: $color-grey-lighter;
    }

    &.today {
      border-color: $color-theme !important;

      &:hover,
      &:focus {
        border-color: $color-theme !important;
        background: $color-theme !important;
        color: #fff !important;
      }
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
      background: $color-theme;
      border-color: $color-theme;
    }

    &.selected.startRange + .endRange,
    &.startRange.startRange + .endRange,
    &.endRange.startRange + .endRange {
      box-shadow: -10px 0 0 $color-theme;
    }

    &.startRange,
    &.prevMonthDay.startRange,
    &.nextMonthDay.startRange,
    &.endRange,
    &.prevMonthDay.endRange,
    &.nextMonthDay.endRange {
      border-color: $color-theme;
    }
  }

  &-time {
    padding: rem-calc(3 5 5);

    input {
      border: solid rem-calc(1) $color-grey-lighter !important;
      height: rem-calc(28);
      font-size: rem-calc(16);
    }

    .flatpickr-am-pm {
      display: none;
    }
  }
}
