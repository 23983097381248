/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2em;
}

.c-page-content {
  padding-bottom: rem_calc(30);
}

.c-content__more-text {
  display: none;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  display: block;
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

@include bp-large('max') {
  .alignleft,
  .alignright,
  .alignnone {
    display: block;
    float: none;
    margin: 1em auto;
  }
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-text--bg {
  padding: rem_calc(20);
  margin: rem_calc(20 0);

  * {
    margin: 0;
  }
}
