/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: underline;
  word-break: break-word;

  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }
}
