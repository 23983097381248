.c-sf {
  &__entry {
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(20);
  }

  &__form-submit-another {
    display: none;
    margin-top: rem-calc(30);

    &.show {
      display: inline-block;
    }
  }

  &__form-section {
    padding: rem-calc(0 0 20);

    h3 {
      display: inline-block;
      margin-bottom: rem-calc(30);
      border-bottom: rem-calc(3) solid $color-grey-light;
      text-transform: uppercase;
    }

    .wp-media-buttons {
      display: none !important;
    }
  }

  &__file-field {
    margin: rem-calc(15 0 25 0);
  }

  &__form-fields,
  &__msg {
    width: 100%;
    max-width: rem-calc(900);
    margin: 0 auto;
  }

  &__msg {
    padding: rem-calc(5 10);
    text-align: center;
    font-size: rem-calc(15);
    color: $color-white;

    &--error {
      background-color: $color-error;
    }

    &--success {
      background-color: $color-success;
    }
  }
}

.mce-inline-toolbar-grp .mce-btn-group .mce-btn:last-child {
  display: none !important;
}
