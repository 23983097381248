/* ==========================================================================
   #LAYOUT
   ========================================================================== */

// Settings
$global-width: $global-max-width;
$breakpoints: (
  small: 0,
  medium-sm: $bp-medium,
  medium: $bp-large,
  large: $bp-xlarge,
  xlarge : $bp-xxlarge,
);
$breakpoint-classes: (small medium-sm medium large xlarge);
$grid-column-gutter: $global-spacing;

// Import Foundation Site grid with dependencies
@import 'foundation-sites/scss/util/util';
@import 'foundation-sites/scss/global';
@import 'foundation-sites/scss/grid/grid';

// Generate custom grid using Foundation Sites Mixins & Functions

.o-layout {
  @include flex-grid-row;

  & .o-layout {
    @include flex-grid-row(nest, $base: false);

    &.collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:not(.is-expanded) .o-layout {
    @include grid-row-size(expand);
  }

  .c-wrapper > & {
    padding-top: rem_calc(15);
  }
}

.o-layout--center-vertically {
  align-items: center;
}

.o-layout--align-right {
  justify-content: flex-end;
}

.o-layout__item {
  @include flex-grid-column;
}

.o-layout__item.o-layout {
  float: none;
  display: block;
}

.o-layout .o-layout__item.o-layout {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

@include -zf-each-breakpoint {
  $j: 20;
  @for $i from 1 through $grid-column-count {
    .o-layout__item--#{$-zf-size}-#{$i} {
      flex: flex-grid-column($i);
      max-width: grid-column($i);

      // @media (max-width: $bp-small) {
      //   flex-basis: 100%;
      //   max-width: 100%;
      // }
    }
  }

  .o-layout__item--#{$-zf-size}-#{$j} {
    flex: 20%;
    max-width: 20%;
  }
}

.o-layout__item--full {
  width: 100%;
  flex-basis: 100%;
}

.o-layout__item--grow {
  flex: 1 0 auto;
}
