/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-grey-extra-light;

  .page-template-front-page & {
    margin-top: 0;
  }

  &__column {
    @include bp-large('max') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &-header {
      padding: rem_calc(10 0 20);

      h3 {
        margin: 0;
        border-color: $color-theme;
      }
    }
  }

  .c-footer__inner {
    max-width: 366px;
    margin: 0 auto;
    padding: rem_calc(40 0 20);
    text-align: center;

    @include bp-large {
      max-width: 100%;
      text-align: left;
    }

    .u-text-left {
      text-align: center;

      @include bp-medium {
        text-align: left;
      }
    }

    .u-text-right {
      text-align: center;

      @include bp-medium {
        text-align: right;
      }
    }
  }
}

.c-footer__copyright {
  align-items: center;
  background-color: $color-grey-extra-light;
  padding: rem_calc(40 0);
  font-size: rem_calc(17);
  border-top: 1px solid $color-grey-lighter;

  @include bp-large('max') {
    padding: rem_calc(25 0);

    * {
      text-align: center !important;
    }
  }

  .o-layout {
    align-items: center;
  }

  .c-footer__logo {
    max-width: rem_calc(160);

    @include bp-large('max') {
      margin: 0 auto rem_calc(20);
    }
  }

  p {
    margin: 0;
  }


  .c-social-media {
    float: right;
    margin: 0;

    @include bp-large('max') {
      float: none;
      margin-top: rem_calc(20);
    }
  }
}
