/* ==========================================================================
   #COURSES
   ========================================================================== */

.c-courses__header {
  font-size: rem-calc(20);
}

.c-courses__meta {
  border-bottom: rem-calc(1) solid $color-grey-medium-light;
  color: $color-grey-medium-light;
  display: inline-block;
  margin-bottom: rem-calc(20);
  padding-bottom: rem-calc(20);
}

.c-courses__teaser {
  color: $color-grey-medium;
}

.c-courses__filter {
  border: rem-calc(2) solid $color-grey-light-medium;
  border-left-width: 0;
  border-right-width: 0;
  padding-top: ($global-spacing / 2);
}

.c-courses__filter-reset {
  color: $color-grey-dark;
  text-decoration: underline;
  //border-left: 1px solid;
  //padding-left: rem-calc(10);
  //margin-left: rem-calc(5);

  &:hover {
    color: $color-grey-dark;
    text-decoration: none;
  }
}

.c-courses__item {
  padding-top: $global-spacing;
  padding-bottom: $global-spacing;
  border-bottom: rem-calc(2) solid $color-grey-light-medium;

  &--left-col {
    @include bp-large('max') {
      text-align: right;
    }
  }

  &--left-col,
  &--right-col {
    @include bp-large('max') {
      order: -1;
      padding-top: rem_calc(5);
      padding-bottom: rem_calc(10);
    }

    @include bp-small('max') {
      flex-basis: 100%;
      max-width: 100%;
      text-align: center !important;
    }
  }
}

.c-courses__title {
  a {
    color: inherit;
    text-decoration: none;
    transition: $global-transition;

    &:hover {
      color: $color-link-hover;
    }
  }

  &-single {
    margin-top: 0;
    margin-bottom: rem_calc(20);
  }
}

.c-courses__details {
  margin-bottom: 0;

  @include bp-small('max') {
    max-width: rem_calc(180);
    margin-left: auto;
    margin-right: auto;
  }

  &-single {
    margin-bottom: rem_calc(10);
    text-align: center;
  }
}

.c-courses__detail {
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  @include bp-small-medium('max') {
    font-size: rem_calc(14);
    margin-bottom: rem_calc(5);

    svg {
      margin-right: rem_calc(10);
    }
  }

  .c-courses__details-single & {
    display: inline-block;
    margin-right: rem_calc(5);
    margin-left: rem_calc(5);

    svg {
      position: relative;
      top: rem_calc(-2);
      margin-right: rem_calc(0);
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.c-courses__thumbnail {
  display: inline-block;

  @include bp-large('max') {
    img {
      max-height: rem_calc(120);
    }
  }

  &-single {
    display: block;

    img {
      display: block;
      margin: rem_calc(0) auto rem_calc(20);
      max-height: rem_calc(600);

      @include bp-large('max') {
        max-height: rem_calc(400);
      }
    }
  }
}
