.gallery {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  //margin-top: - rem_calc(20) !important;

  .wp-caption-text {
    text-align: center;
    font-size: rem_calc(18);

    @include bp-small-medium('max') {
      font-size: rem_calc(16);
    }

    @include bp-small('max') {
      font-size: rem_calc(15);
    }
  }

  &-item {
    padding: rem_calc(5);
    margin: 0;

    img {
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
      border: none;
      transition: $global-transition;
      transform: scale(1);
    }

    .wp-gallery-link:hover img {
      transform: scale(1.1);
    }
  }

  @for $i from 1 through 9 {
    &-columns-#{$i} {
      .wp-caption-text {
        @if $i > 4 {
          font-size: rem_calc(16);
        }
      }
    }

    &-columns-#{$i} &-item {
      width: (100/$i) * 1%;
      flex-basis: (100/$i) * 1%;

      & {
        @include bp-large('max') {
          @if $i > 5 {
            width: 20%;
            flex-basis: 20%;
          }
        }

        @include bp-medium('max') {
          @if $i > 4 {
            width: 25%;
            flex-basis: 25%;
          }
        }

        @include bp-small-medium('max') {
          @if $i > 3 {
            width: 33.333%;
            flex-basis: 33.333%;
          }
        }

        @include bp-small('max') {
          @if $i > 2 {
            width: 50%;
            flex-basis: 50%;
          }
        }
      }
    }
  }
}
