/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: $color-grey;
  line-height: 1.1;
  margin: rem_calc(10 0);

  .c-block__header & {
    color: $color-theme;
  }
}

h1, h2, h3, h4, h5, h6 {
  strong {
    font-weight: 900;
  }
}

/* stylelint-disable selector-no-qualifying-type */

h1, .h1 {
  font-size: rem_calc(68);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(88);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(48);
  }
}

h2, .h2 {
  font-size: rem_calc(48);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(62);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(32);
  }
}

h3, .h3 {
  font-size: rem_calc(24);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(30);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(17);
  }
}

h4, .h4 {
  font-size: rem_calc(20);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(26);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(15);
  }
}

h5, .h5 {
  font-size: rem_calc(18);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(22);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(14);
  }
}

h6, .h6 {
  font-size: rem_calc(16);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(21);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(13);
  }
}

@include bp-custom(1681, 'max') {
  h1, .h1 {
    font-size: rem_calc(58);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(76);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(42);
    }
  }

  h2, .h2 {
    font-size: rem_calc(42);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(54);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(28);
    }
  }

  h3, .h3 {
    font-size: rem_calc(21);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(27);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(16);
    }
  }

  h4, .h4 {
    font-size: rem_calc(18);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(22);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(15);
    }
  }

  h5, .h5 {
    font-size: rem_calc(16);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(20);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(14);
    }
  }

  h6, .h6 {
    font-size: rem_calc(15);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(18);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(13);
    }
  }
}

@include bp-xxlarge('max') {
  h1, .h1 {
    font-size: rem_calc(50);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(65);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(36);
    }
  }

  h2, .h2 {
    font-size: rem_calc(36);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(46);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(24);
    }
  }

  h3, .h3 {
    font-size: rem_calc(19);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(23);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(15);
    }
  }
}

@include bp-xlarge-medium('max') {
  h1, .h1 {
    font-size: rem_calc(41);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(54);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(29);
    }
  }

  h2, .h2 {
    font-size: rem_calc(28);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(38);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(20);
    }
  }
}

@include bp-large('max') {
  h1, .h1 {
    font-size: rem_calc(32);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(42);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(23);
    }
  }

  h2, .h2 {
    font-size: rem_calc(22);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(29);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(18);
    }
  }
}
