/* ==========================================================================
   #LINKS
   ========================================================================== */

.c-link {
  border-bottom: rem-calc(4) solid $color-underline-light;
  color: $color-black;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $color-link;
    border-color: $color-link;
  }
}

.c-link--secondary {
  text-decoration: none;
  color: currentColor;
  border-bottom: none;

  &:hover {
    color: currentColor;
  }
}
