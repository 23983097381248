/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

@include bp-medium {
  .u-text-right-on-large {
    text-align: right;
  }
}

.u-text-center-on-small {
  text-align: center;

  @include bp-medium {
    text-align: left;
  }
}

.alignleft {
  display: block;
  float: none;
  margin: rem_calc(5) auto;

  @include bp-medium {
    //display: static;
    float: left;
    margin: 0 1em 1em 0;
  }
}

.alignright {
  display: block;
  float: none;
  margin: rem_calc(5) auto;

  @include bp-medium {
    //display: static;
    float: right;
    margin: 0 0 1em 1em;
  }
}
