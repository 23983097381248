/* ==========================================================================
   #IMAGES
   ========================================================================== */

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
  font-style: italic; /* [2] */
  vertical-align: middle; /* [3] */

  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;

  .c-block__map &,
  .js-google-map & {
    max-width: none;
    max-height: none;
  }
}


/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */

img[width], /* [1] */
img[height] {  /* [1] */
  max-width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

img[data-src],
img[data-srcset] {
  display: block;
  min-height: 1px;
}
