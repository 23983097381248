.c-header {
  display: block;
  position: relative;
  box-shadow: 0 rem-calc(2) rem-calc(11) - rem-calc(1) rgba(0, 0, 0, 0);
  z-index: 99;
  transition: padding 0.3s ease-in-out;
  box-sizing: border-box;

  &--floating {
    position: absolute;
    padding: rem-calc(10 10);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    @include bp-large('max') {
      &-nomobile {
        padding: rem-calc(0);
        position: relative;
      }
    }

    &.c-header--fixed {
      position: fixed;
    }

    // .body__header--floating.body__fixed-header & {
    //   padding: 0;
    // }

    // .body__fixed-header & {
    //   position: fixed;
    // }
  }

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  &--shrink {
    padding: 0;
    box-shadow: 0 rem-calc(2) rem-calc(11) - rem-calc(1) rgba(0, 0, 0, 0.4);
  }

  &__container {
    position: relative;
    width: 100%;
    margin: auto;
    padding: rem-calc(10 10);
    transition: background 0.3s ease-in-out, padding 0.3s ease-in-out;
  }

  &__mobile {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: -1;

    @include bp-medium('max') {
      display: block;
    }

    .c-header--image:not(&--floating) & {
      display: block;
    }
  }

  &__image-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
  }

  &__inner {
    width: 100%;
    height: rem_calc(110);
    display: flex;
    margin: 0 auto;
    padding: rem-calc(10 10);
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @include bp-large('max') {
      height: rem_calc(80);
      padding: rem-calc(10 10);
    }
  }

  &--shrink &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
      padding: rem-calc(0 10);
    }
  }

  &--shrink &__container {
    padding: rem-calc(10 20);
  }

  @include bp-large('max') {
    &--floating-nomobile#{&}--shrink &__container {
      padding: rem-calc(10 10);
    }
  }

  &--full#{&}--static &__inner,
  &--full#{&}--floating &__inner {
    @media (min-width: $bp-nav-full-padding--default) {
      padding: rem-calc(5 60);
    }
  }

  &--container &__inner {
    max-width: $global-max-width;
  }

  &--floating &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
      padding: rem-calc(0 10);
    }
  }

  &--floating:not(&--full) &__container {
    max-width: $global-max-width;
    padding: rem-calc(10 0);

    .body__fixed-header--floating & {
      max-width: none;
    }
  }

  &--floating#{&}--shrink &__container {
    max-width: none;
  }

  &--static#{&}--image &__container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &--static#{&}--image {
    .c-header__inner {
      height: rem_calc(210);

      @include bp-xxlarge('max') {
        height: rem_calc(180);
      }

      @include bp-large('max') {
        height: rem_calc(120);
        align-items: flex-start;
      }
    }
  }
}

.c-header__logo {
  position: relative;
  width: 25%;
  height: 100%;

  a {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: rem_calc(300);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-decoration: none;
  }

  &--color {
    padding: 5px;
  }

  @include bp-xxlarge('max') {
    width: 20%;
  }

  @include bp-xlarge-medium('max') {
    width: 17%;
  }

  @include bp-large('max') {
    max-width: rem_calc(190);
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;

  @include bp-xxlarge('max') {
    width: 80%;
  }

  @include bp-xlarge-medium('max') {
    width: 83%;
  }

  @include bp-large('max') {
    width: auto;
  }

  .c-header__inner--nologo & {
    width: 100%;
  }
}

.c-header__mobile-actions {
  display: none;

  @include bp-large('max') {
    display: flex;
    align-items: center;
  }

  .c-header--static.c-header--image & {
    margin-top: rem_calc(10);
  }
}

.c-search {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-grey, 0.95);
  transition: $global-transition;
  z-index: 999;

  &__form {
    max-width: 60%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    z-index: 3;
    border: rem-calc(20) solid $color-underline-theme;
    transition: $global-transition;

    @include bp-large('max') {
      border-width: rem-calc(10);
      max-width: rem_calc(500);
    }

    &:hover {
      border-color: $color-underline-light !important;
    }
  }

  [type=text]#{&}__input {
    height: rem-calc(70);
    padding: rem-calc(10);
    position: relative;
    border: none;
    margin: 0;
    border-radius: 0;
    flex: 1 1 0;

    @include bp-large('max') {
      height: rem-calc(50);
    }
  }

  &__btn {
    height: rem-calc(70);
    position: relative;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    cursor: pointer;
    border: none;

    @include bp-large('max') {
      height: rem-calc(50);
    }
  }

  &__close {
    position: absolute;
    display: block;
    top: rem-calc(30);
    right: rem-calc(30);
    width: rem-calc(40);
    height: rem-calc(40);
    font-size: rem-calc(60);
    line-height: 2rem;
    color: $color-underline-theme;
    text-decoration: none;
    transition: $global-transition;

    @include bp-large('max') {
      top: rem-calc(20);
      right: rem-calc(20);
    }

    &:hover {
      color: $color-white !important;
      text-decoration: none !important;
    }
  }
}
