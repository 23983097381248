/* ==========================================================================
   #BREADCRUMB
   ========================================================================== */

.c-breadcrumb {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(20);
}

.c-breadcrumb__item:not(:last-child) {
  position: relative;
  padding-right: rem-calc(10);
  margin-right: rem-calc(16);

  &::after {
    content: '';
    width: rem-calc(2);
    height: 100%;
    background-color: $color-grey;
    position: absolute;
    right: rem-calc(-5);
    top: 0;
    transform: rotate(15deg);
  }
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: $color-grey-dark;
  text-decoration: underline;

  &:hover {
    color: $color-grey-dark;
    text-decoration: none;
  }
}
