/* ==========================================================================
   #LISTS
   ========================================================================== */

ul,
ol {
  margin-left: rem-calc(30);
}

li {
  margin-bottom: rem-calc(8);

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: rem-calc(16);
    margin-top: rem-calc(8);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: rem-calc(16);
  margin-left: rem-calc(16);
}
