/* ==========================================================================
   #CARD
   ========================================================================== */

.c-card {
  box-shadow: 0 0 0 1px $color-grey-lighter-medium inset;
  padding: rem_calc(10);

  .c-newsletters-page & {
    max-width: rem_calc(410);
  }
}

.c-card--primary {
  box-shadow: 0 0 0 4px $color-underline-theme inset;
}
