.c-events-calendar {
  position: relative;
  margin: 0 auto;
  width: 100%;

  &--size-75 {
    @include bp-xlarge {
      width: 75%;
    }
  }

  &--size-66 {
    @include bp-xlarge-medium {
      width: 66%;
    }
  }

  &--size-50 {
    @include bp-xlarge-medium {
      width: 50%;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: rem-calc(60);
    z-index: 9999;
    transform: translateY(-50%);
  }

  .fc-header-toolbar {
    h2 {
      position: relative;
      font-size: rem_calc(32);

      @include bp-xlarge('max') {
        top: rem-calc(5);
        font-size: rem_calc(28);
      }

      @include bp-large('max') {
        font-size: rem_calc(24);
      }

      @include bp-medium('max') {
        font-size: rem_calc(18);
      }
    }

    .fc-button {
      background-color: $color-theme;
      background-image: none;
      color: $color-white;
      text-shadow: none;
      transition: $global-transition;

      @include bp-xlarge('max') {
        font-size: rem_calc(16);
      }

      @include bp-medium('max') {
        font-size: rem_calc(14);
        line-height: rem_calc(14);
        padding: rem-calc(0 5);
      }

      &:hover {
        background-color: $color-link-hover;
      }
    }

    .fc-state-disabled,
    .fc-today-button {
      background-color: $color-theme-alt;
      opacity: 1;

      &:hover {
        background-color: $color-theme-alt-light;
      }
    }
  }

  &.fc {
    table {
      margin: 0;
    }
  }

  .fc-day-header {
    @include bp-medium('max') {
      font-size: rem_calc(14);
    }
  }

  td.fc-today { /* stylelint-disable-line selector-no-qualifying-type */
    background-color: $color-grey-lighter;
  }

  .fc-event-container {
    .fc-event {
      position: relative;
      padding: rem-calc(1 5);
      background-color: $color-theme-alt;
      border: none;
      z-index: 999;
      transition: $global-transition;

      &:hover {
        background-color: $color-theme-alt-light;
      }
    }

    .fc-title,
    .fc-content {
      white-space: normal !important;
      overflow: auto !important;
    }
  }

  .addtocalendar {
    position: absolute;
    top: rem-calc(5);
    left: auto;
    display: block;
    z-index: 999;
    padding-left: 0;

    .atcb-link {
      position: absolute;
      right: rem-calc(15);
      padding: rem-calc(12 0);
      width: 26px;
      background-position: center center;
    }

    .atcb-list {
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      z-index: 99;
    }
  }
}
