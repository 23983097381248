@mixin video_play_btn() {
  .video-play-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0.9);
    left: 0;
    right: 0;
    margin: auto;
    width: rem_calc(70);
    height: rem_calc(70);
    z-index: 9;
    background-image: url('owl.video.play.png');
    background-size: 100% auto;
    cursor: pointer;
    transition: $global-transition;

    &:hover {
      transform: translateY(-50%) scale(1);
    }
  }
}

@mixin video_box() {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  //padding-top: rem_calc(30);
  background-color: $color-black;

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @include video_play_btn();
}
