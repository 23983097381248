/* ==========================================================================
   #SECTION
   ========================================================================== */

$section-spacing: $global-spacing * 2 !default;

.c-section {
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;

  @include bp-large('max') {
    padding-top: $section-spacing/2;
    padding-bottom: $section-spacing/2;
  }
}

.c-section--primary {
  background-color: $color-theme-light;
}

.c-section--secondary {
  background-color: $color-grey-lighter;
}

.c-section--inner {
  padding: rem-calc(30);
}

.c-section--overlap {
  margin-top: rem-calc(-200);

  @include bp-large('max') {
    margin-top: rem-calc(-120);
  }
}

.c-section-below {
  padding-top: 0;
}

.c-section--teased {
  padding-top: 0;
  margin-top: rem-calc(-160);

  @include bp-large('max') {
    margin-top: rem-calc(-120);
  }

  @include bp-medium('max') {
    margin-top: 0;
    padding-top: rem-calc(40);
  }
}
