/* ==========================================================================
   #ENTRY
   ========================================================================== */

.c-entry__header {
  background-color: $color-grey-lighter;
  padding: rem-calc(35);
  margin-bottom: rem-calc(50);
}

.c-entry__title {
  font-size: rem-calc(26);
  font-weight: bold;

  @include bp-medium {
    font-size: rem-calc(30);
  }

  @include bp-large {
    font-size: rem-calc(36);
  }
}

.c-entry__meta {
  font-size: rem-calc(18);
}

.c-entry__meta-item {
  border-left: rem-calc(2) solid $color-black;
  margin-right: rem-calc(6);
  padding-left: rem-calc(8);

  &:first-child {
    padding-left: 0;
    border-left: none;
  }
}

.c-entry__author {
  font-size: rem-calc(18);
}

.c-entry__author-bio {
  line-height: rem-calc(32);
}

.c-entry__content {
  font-size: rem-calc(18);
  margin: rem-calc(10 0);

  @include bp-medium {
    margin: rem-calc(30 0 50);
  }
}

.c-entry__main-img {
  margin-bottom: rem-calc(20);
}

.c-entry__lead {
  font-size: rem-calc(24);
  line-height: rem-calc(42);
  font-style: italic;
  margin-bottom: rem-calc(50);
}
