/* ==========================================================================
   #DISPLAY
   ========================================================================== */

.u-flex {
  display: flex;
}

.u-flex-center-vertically {
  align-items: center;
}

.u-inline-block {
  display: inline-block;
}
