/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

// * {
//   //outline: none;
// }

html, body {
  overflow-x: hidden; /* Cut off in case something is out of the page */
}

.body {
  &__fixed-header {
    padding-top: rem_calc(120);

    @include bp-large('max') {
      padding-top: rem_calc(100);
    }
  }

  &__fixed-header#{&}__floating-header {
    padding-top: 0;

    @include bp-medium('max') {
      padding-top: rem_calc(80);
    }
  }
}

.body__relative-header {
  padding-top: 0;
}

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: rem-calc(20);
}


/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: rem-calc(16);
}

b, strong {
  font-weight: bold;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
