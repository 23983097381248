.video-wrap {
  opacity: 0;

  &.video-wrap-50 {
    width: 50%;

    video {
      height: auto;
    }
  }

  &.alignright {
    padding-left: rem_calc(40);
  }

  &.alignleft {
    padding-right: rem_calc(40);
  }
}

.mejs-container {
  background-color: transparent !important;
}
