/* ==========================================================================
   #ALERT
   ========================================================================== */

.c-alert {
  @include alert-base;
}

.c-alert--error {
  @include alert-error;
}

.c-alert--info {
  @include alert-info;
}
