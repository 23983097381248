.cform__entry {
  padding: rem_calc(50 0 60);

  @include bp-large('max') {
    padding: rem_calc(30 0 40);
  }

  @include bp-medium('max') {
    padding: rem_calc(20 0 30);
  }

  * {
    font-weight: 600;
  }
}

.c-form-field--small {
  height: rem_calc(50);
  padding: rem-calc(5 10);
  font-size: rem_calc(16);
  border-width: rem-calc(2) !important;
}

select.c-form-field--small { /* stylelint-disable-line selector-no-qualifying-type */
  background-size: 20px auto;
}

.c-form-group {
  margin-bottom: rem_calc(10);

  .select2-container {
    margin-bottom: rem_calc(5);
  }

  label,
  legend {
    margin-bottom: rem_calc(5);

    span {
      position: relative;
      top: -3px;
      font-size: 13px;
      color: $color-error;
    }
  }

  .c-field__label {
    position: relative;
    display: block;
    font-size: rem_calc(18);
    font-weight: 600;

    @include bp-medium('max') {
      font-size: rem_calc(16);
    }

    &--checkbox {
      min-height: rem_calc(30);
      margin: rem_calc(10 0);
      padding: rem_calc(0 0 0 10);

      @include bp-medium('max') {
        margin: rem_calc(5 0);
        padding: rem_calc(3 0 0 8);
      }

      [type=checkbox],
      [type=radio] {
        opacity: 0;

        &.focus-visible ~ svg {
          stroke-width: 1px;
        }
      }
    }

    .c-icon {
      top: rem_calc(-4);
      left: rem_calc(-3);
      position: absolute;
      display: inline-block;
      width: rem_calc(36);
      height: rem_calc(36);
      stroke-width: 0;
      stroke: $color-theme;
      fill: $color-theme;

      &--checkbox-unchecked {
        top: rem_calc(-6);
        left: rem_calc(-5);
        width: rem_calc(40);
        height: rem_calc(40);
      }

      &--checkbox-checked,
      &--radio-checked {
        opacity: 0;
      }

      &--radio-unchecked {
        top: rem_calc(-1);
        left: rem_calc(-2);
        width: rem_calc(31);
        height: rem_calc(31);
      }

      &--radio-checked {
        top: rem_calc(0);
        left: rem_calc(-1);
        width: rem_calc(29);
        height: rem_calc(29);
      }

      @include bp-medium('max') {
        transform: scale(0.8);
      }
    }

    [type=checkbox]:checked ~ .c-icon--checkbox-unchecked,
    [type=radio]:checked ~ .c-icon--radio-unchecked {
      opacity: 0;
    }

    [type=checkbox]:checked ~ .c-icon--checkbox-checked,
    [type=radio]:checked ~ .c-icon--radio-checked {
      opacity: 1;
    }
  }

  .c-field__label--clearfix {
    display: none;
  }

  .c-field__label--vertical + .c-field__label--clearfix {
    display: block;
  }

  .c-field__label--horizontal {
    display: inline-block;
    margin: rem_calc(10 10 10 0);
  }

  legend {
    padding: 0;
    color: $color-theme;
  }

  fieldset {
    padding: 0;
    //border: solid 1px #d8dadd;
  }
}

.c-form__control {
  &.c-form__submit {
    height: auto !important;
    margin: rem_calc(0) auto;
  }

  &.c-form__textarea {
    height: rem_calc(150) !important;

    &--medium {
      height: rem_calc(200) !important;
    }

    &--large {
      height: rem_calc(250) !important;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.c-field__error {
  padding-left: rem_calc(3);
  font-size: rem_calc(14);
  color: $color-error;
  display: none;

  &.show {
    display: block;
  }

  @include bp-medium('max') {
    font-size: rem_calc(13);
  }
}

.c-field__message {
  display: none;
  font-size: rem_calc(18);

  &.show {
    display: block;
    width: 80%;
    margin: rem_calc(10) auto 0;
    padding: rem_calc(10);
    text-align: center;
    color: $color-white;
  }

  &.error {
    background-color: $color-error;
  }

  &.success {
    background-color: $color-success;
  }

  @include bp-xlarge('max') {
    font-size: rem_calc(16);

    &.show {
      padding: rem_calc(8);
    }
  }

  @include bp-medium('max') {
    font-size: rem_calc(15);

    &.show {
      width: 100%;
    }
  }
}

.c-field__break {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.c-form__message {
  padding: 50px 0;
}

.c-newsletter__form {
  .o-layout {
    position: relative;
    left: - rem_calc(13);
    width: calc(100% + 26px);
    align-items: flex-start;
  }

  .c-form__input,
  .c-btn {
    width: 100%;
    height: rem_calc(72);
    margin: 0;
    font-weight: 500;

    @include bp-xxlarge('max') {
      height: rem_calc(66);
    }

    @include bp-large('max') {
      height: rem_calc(62);
      padding: rem_calc(15);
    }

    @include bp-medium('max') {
      height: rem_calc(50);
      padding: rem_calc(10);
    }
  }

  @include bp-medium('max') {
    .c-btn {
      font-size: rem_calc(18);
    }
  }

  .c-form__input {
    border-color: $color-white;
    color: $color-grey-dark;

    @include bp-large('max') {
      margin-bottom: rem_calc(0);
    }

    &:hover,
    &:focus {
      border-color: $color-theme-alt;

      @include bp-medium('max') {
        border-width: rem-calc(2);
      }
    }

    &::placeholder {
      color: $color-grey-dark;
    }
  }
}
