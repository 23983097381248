.c-book {
  padding-top: rem_calc(10);
  padding-bottom: rem_calc(20);

  a {
    text-decoration: none;
    color: inherit;
    transition: $global-transition;

    &:hover {
      color: $color-link;
    }
  }

  &__inner {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: rem_calc(5);
    border: 1px solid $color-grey-lighter;
  }

  @include bp-small('max') {
    text-align: center;

    &__inner {
      max-width: rem_calc(350);
      margin: 0 auto;
    }
  }
}

.c-book__single {
  padding-top: rem_calc(30);
}

.c-book__cover {
  padding-right: rem_calc(15);
  width: 50%;
  max-width: rem_calc(190);
  float: left;

  @include bp-small('max') {
    width: 100%;
    float: none;
    padding-right: 0;
    margin: 0 auto rem_calc(10);
  }

  .c-book__single & {
    float: none;
    width: 100%;
    max-width: rem_calc(350);
    margin-bottom: rem_calc(30);
  }
}

.c-book__info {
  overflow: hidden;
}

.c-book__title {
  margin-top: 0;
  margin-bottom: rem_calc(15);
  color: $color-grey-dark;
}

.c-book__authors {
  h6 {
    margin: rem_calc(0 0 15);
    display: inline-block;
    color: $color-grey-light;
  }

  a {
    font-weight: 700;
    color: $color-grey;
  }
}

.c-book__publisher,
.c-book__isbn {
  margin: 0 0 rem_calc(5);
  font-size: rem_calc(16);
}

.c-book__single {
  .c-book__isbn {
    margin: 0 0 rem_calc(25);
  }
}

.c-books__orderby {
  padding-bottom: rem_calc(30);

  label {
    display: block;
    padding-bottom: rem_calc(5);
    color: $color-grey;
  }

  select {
    max-width: rem_calc(300);
  }
}

.c-books__dropdown {
  max-width: rem_calc(300);
}
