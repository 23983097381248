/* ==========================================================================
   #SOCIAL MEDIA
   ========================================================================== */

.c-social-media {
  @include clearfix;
  list-style: none;
  margin: 0;
}

.c-social-media__item {
  display: inline-block;
  margin: 0 rem-calc(20) 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.c-social-media__link {
  border: rem-calc(2) solid $color-black;
  border-radius: 50%;
  display: block;
  height: rem-calc(36);
  width: rem-calc(36);
  padding: rem-calc(8);

  &:hover {
    border-color: $color-underline-theme;

    .c-social-media__icon {
      fill: $color-underline-theme;
    }
  }
}

.c-social-media__link--square {
  border-radius: 0;
}

.c-social-media__icon {
  display: block;
  height: rem-calc(16);
  width: rem-calc(16);
}
