.select2-container .select2-selection--single {
  height: rem_calc(70);
  border: rem-calc(3) solid $color-theme;
  border-radius: 0;

  @include bp-xxlarge('max') {
    height: rem_calc(66);
  }

  @include bp-large('max') {
    height: rem_calc(62);
  }

  @include bp-medium('max') {
    height: rem_calc(50);
    border-width: rem-calc(2);
  }

  &.select2--small {
    height: rem_calc(50);
  }

  &.select2--xsmall {
    height: rem_calc(42);
    border: rem-calc(2) solid $color-theme;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: rem_calc(63);
  color: $color-theme;

  @include bp-xxlarge('max') {
    line-height: rem_calc(56);
  }

  @include bp-large('max') {
    line-height: rem_calc(52);
  }

  @include bp-medium('max') {
    line-height: rem_calc(36);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: rem_calc(43);

  @include bp-medium('max') {
    line-height: rem_calc(36);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  line-height: rem_calc(38);

  @include bp-medium('max') {
    line-height: rem_calc(30);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: rem_calc(70);

  @include bp-xxlarge('max') {
    height: rem_calc(66);
  }

  @include bp-large('max') {
    height: rem_calc(62);
  }

  @include bp-medium('max') {
    height: rem_calc(50);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: rem_calc(50);
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: rem_calc(42);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $color-theme transparent transparent transparent;
  border-style: solid;
  border-width: rem_calc(7) rem_calc(6) 0 rem_calc(6);
  height: 0;
  left: 50%;
  margin-left: rem_calc(-15);
  margin-top: rem_calc(-4);
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $color-theme transparent;
  border-width: 0 rem_calc(6) rem_calc(7) rem_calc(6);
}

.select2-dropdown {
  background-color: $color-white;
  border: rem_calc(3) solid $color-theme;
  border-radius: 0;

  &.select2--xsmall {
    border-width: rem_calc(2);
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: rem_calc(15);
  padding-right: rem_calc(15);
  font-size: rem_calc(18);
  font-weight: 600;

  @include bp-medium('max') {
    padding: rem-calc(5 10);
    font-size: rem_calc(16);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  padding-left: rem_calc(10);
  padding-right: rem_calc(10);
}

.select2-results__option {
  padding: rem_calc(8 15);
  margin: 0;

  @include bp-medium('max') {
    padding: rem_calc(7 10);
    font-size: rem_calc(15);
  }

  .select2-results__options.first-as-label &:first-child {
    padding: rem_calc(13 15);
    background-color: $color-theme-light;
    color: $color-white;

    @include bp-medium('max') {
      padding: rem_calc(10 15);
    }
  }

  .select2--xsmall & {
    padding: rem_calc(7 10);

    @include bp-medium('max') {
      padding: rem_calc(6 10);
    }
  }

  .select2--xsmall .select2-results__options.first-as-label &:first-child {
    padding: rem_calc(10 10);

    @include bp-medium('max') {
      padding: rem_calc(8 10);
    }
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $color-grey-lighter;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-theme-alt;
  color: $color-white;
}
