/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
  line-height: rem-calc(34);
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  padding: 0;
  margin: rem_calc(10 0);

  @include bp-xlarge('max') {
    line-height: rem-calc(30);
  }

  @include bp-medium('max') {
    justify-content: center;
    flex-direction: column;
  }
}

.c-pagination__items {
  display: flex;
  list-style: none;
  margin: 0;
  justify-content: center;
}

.c-pagination__item {
  margin-bottom: 0;
}

.c-pagination__link {
  border: rem-calc(2) solid transparent;
  margin: rem_calc(5);
  padding-left: rem-calc(11);
  padding-right: rem-calc(11);
  color: $color-theme;
  font-size: rem_calc(20);
  display: inline-block;
  text-decoration: none;
  transition: $global-transition;

  @include bp-xlarge('max') {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
    font-size: rem_calc(16);
  }

  @include bp-medium('max') {
    margin: rem_calc(5 3);
  }

  .icon {
    position: relative;
    top: rem-calc(5);
    width: 1rem;
    height: 1.5rem;
    fill: $color-theme;

    @include bp-xlarge('max') {
      top: rem-calc(4);
      height: 1.3rem;
    }
  }

  &--current,
  &:hover {
    border-color: $color-theme;
  }
}

.c-pagination__link--inactive {
  cursor: default;
}
