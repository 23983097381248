/* ==========================================================================
   #CALENDAR
   ========================================================================== */

// DEFAULT

.c-calendar__table {
  display: table;

  @include bp-large('max') {
    display: none;
  }
}

.c-calendar__heading {
  position: relative;
  display: block;
  margin-bottom: rem-calc(40);

  @include bp-medium('max') {
    margin-bottom: rem-calc(30);
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.c-calendar__pager {
  background-color: $color-grey-light-medium;
  border-radius: $global-radius;
  padding-right: rem-calc(10);
  padding-left: rem-calc(10);
  height: rem-calc(30);
  margin-top: rem-calc(30);

  @include bp-medium {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

.c-calendar__pager-prev,
.c-calendar__pager-next {
  float: left;
  background-color: $color-white;
  border-radius: 0 $global-radius $global-radius 0;
  border: 1px solid $color-grey-lighter-medium;
  height: rem-calc(36);
  top: rem-calc(-4);
  position: relative;

  &:hover {
    border-color: $color-grey-medium-light;
  }
}

.c-calendar__pager-prev {
  border-radius: $global-radius 0 0 $global-radius;
  right: rem-calc(-1);

  &:hover {
    z-index: 1;
  }
}

.c-calendar__pager-link {
  display: block;
  font-size: rem-calc(14);
  line-height: rem-calc(36);
  padding: rem-calc(0 10);
  color: $color-grey-medium;
  text-decoration: none;

  &:hover {
    color: $color-grey-medium;
    text-decoration: none;
  }
}

.c-calendar__pager-link--inactive {
  color: $color-grey-light-medium;

  &:hover {
    color: $color-grey-light-medium;
  }
}

.c-calendar__header {
  background-color: $color-grey-lighter;
  color: $color-grey-medium-light;
  font-weight: bold;
  border: 1px solid $color-grey-lighter-medium;
}

.c-calendar__date-box,
.c-calendar__single-day {
  width: 14%;
  border: 1px solid $color-grey-lighter-medium;
  color: $color-grey-medium-light;
  text-align: right;
  vertical-align: top;
  margin: 0;
  padding: rem-calc(2);
}

.c-calendar__date-box {
  border-bottom: none;
}

.c-calendar__single-day {
  border-top: none;
}

.c-calendar__date-box--past,
.c-calendar__date-box--future,
.c-calendar__single-day--past,
.c-calendar__single-day--future {
  background-color: lighten($color-grey-lighter, 2.5%);
  color: $color-grey-light;
}

.c-calendar__date-box--today {
  border: rem-calc(2) solid $color-theme;
  border-bottom-width: 0;
}

.c-calendar__single-day--today {
  border: rem-calc(2) solid $color-theme;
  border-top-width: 0;
}

.c-calendar__single-day .c-calendar__inner {
  height: rem-calc(150);
  line-height: rem-calc(44);
  overflow: auto;

  @include bp-xlarge-medium('max') {
    height: rem_calc(120);
  }
}

.c-calendar__single-day .c-calendar__inner--full {
  line-height: normal;
  height: rem_calc(205);
  // height: $calendar-cell-content-max-height;
  // overflow: auto;
  font-size: rem-calc(13);
  padding: rem-calc(5);

  @include bp-xlarge-medium('max') {
    height: rem_calc(160);
  }
}

.c-calendar__inner hr {
  margin: rem_calc(10 0);
}

.c-calendar__list {
  @include bp-large {
    display: none;
  }
}

.c-calendar__event-title {
  margin-bottom: rem-calc(5);
  text-align: left;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $color-theme;
    }
  }
}

.c-calendar__list-item {
  border-bottom: 1px solid $color-grey-lighter-medium;
  padding: rem-calc(5 10);
  margin-bottom: 0;

  ul {
    margin-left: rem_calc(10);
  }

  ul li {
    list-style-type: none;

    p {
      margin-bottom: rem_calc(5);
    }
  }
}

.c-calendar__list-item--today {
  background-color: $color-grey-light;
}
