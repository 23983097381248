/* ==========================================================================
   #HEADING
   ========================================================================== */

.c-heading {
  display: inline-block;
  border-bottom: rem-calc(4) solid $color-underline-light;
  padding-bottom: rem-calc(4);
}

.c-heading--full {
  display: block;
}

.c-heading--secondary {
  border: none;
  padding-bottom: 0;
  color: $color-grey-medium;
}

.c-heading--big {
  font-size: rem-calc(20);

  @include bp-medium {
    font-size: rem-calc(26);
  }
}

.c-page__title {
  margin-bottom: rem_calc(25);
  font-size: rem-calc(36);

  @include bp-xxlarge('max') {
    font-size: rem-calc(32);
    margin-bottom: rem_calc(20);
  }

  @include bp-xlarge-medium('max') {
    font-size: rem-calc(28);
    margin-bottom: rem_calc(15);
  }

  @include bp-large('max') {
    font-size: rem-calc(24);
    margin-bottom: rem_calc(10);
  }

  // @include bp-medium {
  //   font-size: rem-calc(24);
  // }

  // @include bp-large {
  //   font-size: rem-calc(28);
  // }
}
