.cform__loader {
  position: relative;
  top: 0;
  display: none;
  margin: auto;
  width: 40px;
  height: 40px;
  background-size: 100%;

  &.show {
    display: block;
  }
}

@include bp-xxlarge() {
  .c-page-404 {
    h3 {
      padding: rem_calc(30 0);
    }
  }
}

.c-skip-to-main-content {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  z-index: -1;

  &:focus {
    position: relative;
    opacity: 1;
  }
}
