/* ==========================================================================
   #ADD TO CALENDAR
   custom styles for: http://addtocalendar.com/
   using custom naming for classes in order to sync with plugin
   ========================================================================== */

.addtocalendar {
  position: relative;
  display: block;
  max-width: rem_calc(350);
  width: 100%;
  padding-left: rem_calc(20);

  &:hover .atcb-link {
    color: $color-link-alt;
  }
}

.atcb-list {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: rem_calc(20);
  right: 0;
  z-index: 900;
  list-style: none;
  margin: 0;
  padding: rem-calc(10 10 8);
  background-color: $color-link-alt;
  border-radius: 0 0 $global-radius $global-radius;
  box-shadow: rem_calc(0 5 20) $color-grey-lighter-medium;
  text-align: left;

  &::before {
    content: '';
    width: rem-calc(10);
    height: rem-calc(10);
    background-color: $color-white;
    position: absolute;
    top: rem-calc(-6);
    right: rem-calc(10);
    transform: rotate(45deg);
  }
}

.atcb-link {
  color: $color-white;
  text-decoration: none;
  border-radius: $global-radius;
  border: 1px solid $color-link-alt;
  display: block;
  padding: rem-calc(8 10 8 40);
  cursor: pointer;
  background: $color-link-alt url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5MiAxNjY0aDI4OHYtMjg4aC0yODh2Mjg4em0zNTIgMGgzMjB2LTI4OGgtMzIwdjI4OHptLTM1Mi0zNTJoMjg4di0zMjBoLTI4OHYzMjB6bTM1MiAwaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTM4NGgyODh2LTI4OGgtMjg4djI4OHptNzM2IDczNmgzMjB2LTI4OGgtMzIwdjI4OHptLTM4NC03MzZoMzIwdi0yODhoLTMyMHYyODh6bTc2OCA3MzZoMjg4di0yODhoLTI4OHYyODh6bS0zODQtMzUyaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTg2NHYtMjg4cTAtMTMtOS41LTIyLjV0LTIyLjUtOS41aC02NHEtMTMgMC0yMi41IDkuNXQtOS41IDIyLjV2Mjg4cTAgMTMgOS41IDIyLjV0MjIuNSA5LjVoNjRxMTMgMCAyMi41LTkuNXQ5LjUtMjIuNXptNzM2IDg2NGgyODh2LTMyMGgtMjg4djMyMHptLTM4NC0zODRoMzIwdi0yODhoLTMyMHYyODh6bTM4NCAwaDI4OHYtMjg4aC0yODh2Mjg4em0zMi00ODB2LTI4OHEwLTEzLTkuNS0yMi41dC0yMi41LTkuNWgtNjRxLTEzIDAtMjIuNSA5LjV0LTkuNSAyMi41djI4OHEwIDEzIDkuNSAyMi41dDIyLjUgOS41aDY0cTEzIDAgMjIuNS05LjV0OS41LTIyLjV6bTM4NC02NHYxMjgwcTAgNTItMzggOTB0LTkwIDM4aC0xNDA4cS01MiAwLTkwLTM4dC0zOC05MHYtMTI4MHEwLTUyIDM4LTkwdDkwLTM4aDEyOHYtOTZxMC02NiA0Ny0xMTN0MTEzLTQ3aDY0cTY2IDAgMTEzIDQ3dDQ3IDExM3Y5NmgzODR2LTk2cTAtNjYgNDctMTEzdDExMy00N2g2NHE2NiAwIDExMyA0N3Q0NyAxMTN2OTZoMTI4cTUyIDAgOTAgMzh0MzggOTB6IiBmaWxsPSIjZmZmIi8+PC9zdmc+') no-repeat 10px center;

  &:focus,
  &:hover {
    color: $color-link-alt;
    background-color: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5MiAxNjY0aDI4OHYtMjg4aC0yODh2Mjg4em0zNTIgMGgzMjB2LTI4OGgtMzIwdjI4OHptLTM1Mi0zNTJoMjg4di0zMjBoLTI4OHYzMjB6bTM1MiAwaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTM4NGgyODh2LTI4OGgtMjg4djI4OHptNzM2IDczNmgzMjB2LTI4OGgtMzIwdjI4OHptLTM4NC03MzZoMzIwdi0yODhoLTMyMHYyODh6bTc2OCA3MzZoMjg4di0yODhoLTI4OHYyODh6bS0zODQtMzUyaDMyMHYtMzIwaC0zMjB2MzIwem0tMzUyLTg2NHYtMjg4cTAtMTMtOS41LTIyLjV0LTIyLjUtOS41aC02NHEtMTMgMC0yMi41IDkuNXQtOS41IDIyLjV2Mjg4cTAgMTMgOS41IDIyLjV0MjIuNSA5LjVoNjRxMTMgMCAyMi41LTkuNXQ5LjUtMjIuNXptNzM2IDg2NGgyODh2LTMyMGgtMjg4djMyMHptLTM4NC0zODRoMzIwdi0yODhoLTMyMHYyODh6bTM4NCAwaDI4OHYtMjg4aC0yODh2Mjg4em0zMi00ODB2LTI4OHEwLTEzLTkuNS0yMi41dC0yMi41LTkuNWgtNjRxLTEzIDAtMjIuNSA5LjV0LTkuNSAyMi41djI4OHEwIDEzIDkuNSAyMi41dDIyLjUgOS41aDY0cTEzIDAgMjIuNS05LjV0OS41LTIyLjV6bTM4NC02NHYxMjgwcTAgNTItMzggOTB0LTkwIDM4aC0xNDA4cS01MiAwLTkwLTM4dC0zOC05MHYtMTI4MHEwLTUyIDM4LTkwdDkwLTM4aDEyOHYtOTZxMC02NiA0Ny0xMTN0MTEzLTQ3aDY0cTY2IDAgMTEzIDQ3dDQ3IDExM3Y5NmgzODR2LTk2cTAtNjYgNDctMTEzdDExMy00N2g2NHE2NiAwIDExMyA0N3Q0NyAxMTN2OTZoMTI4cTUyIDAgOTAgMzh0MzggOTB6IiBmaWxsPSIjMDA2Y2U1Ii8+PC9zdmc+');
  }

  &:focus {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.atcb-link:focus ~ ul,
.atcb-link:active ~ ul,
.atcb-list:hover {
  visibility: visible;
}

.atcb-item-link {
  color: $color-white;
  display: block;

  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

