/* ==========================================================================
   #MEMBERS
   ========================================================================== */

.c-members__categories-buttons {
  li {
    flex: 0 1 auto;
    margin: 0;

    @include bp-large('max') {
      padding-right: rem_calc(0);
    }
  }

  @include bp-medium('max') {
    display: none;
  }
}

.c-members__categories-dropdown {
  &.hide {
    display: none;

    @include bp-medium('max') {
      display: block;
    }
  }
}

.c-member__inner {
  max-width: 420px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  border-bottom: rem-calc(4) solid $color-grey-light-medium;

  @include bp-large('max') {
    margin-left: auto;
    margin-right: auto;
  }

  @include bp-medium('max') {
    max-width: 300px;
  }

  p {
    margin: 0;
  }
}

.c-member__title {
  background-color: $color-grey-light-medium;
  font-size: rem-calc(22);
}

.c-member__dept-pos.notempty,
.c-member__address.notempty,
.c-member__hours.notempty,
.c-member__specialty.notempty,
.c-member__info.notempty,
.c-member__phone.notempty {
  padding-bottom: rem_calc(15);
}

.c-member__email.notempty {
  padding-bottom: rem_calc(5);
}

.c-member__content {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}


.c-member__link {
  display: block;
  color: $color-black;
  text-decoration: none;
  padding: rem-calc(10 20);

  &:hover {
    color: $color-black;
  }
}

.c-email__icon,
.c-phone__icon {
  width: rem_calc(20);
  height: rem_calc(20);
  display: inline-block;
  vertical-align: top;
}

.c-member-list-item {
  a {
    transition: $global-transition;

    &:hover {
      color: $color-theme;
    }
  }

  p {
    margin-bottom: rem_calc(10);
  }

  &__inner {
    padding-top: rem-calc(10);

    @include bp-small-medium('max') {
      max-width: 300px !important;
      margin: 0 auto !important;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      margin-bottom: rem-calc(13);
      margin-left: rem-calc(13);
      margin-right: rem-calc(13);
      border-top: 1px solid $color-grey-light;

      @include bp-small-medium('max') {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__image {
    a {
      display: inline-block;
    }
  }

  &__image,
  &__data {
    @include bp-small-medium('max') {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title {
    padding-bottom: rem_calc(5);
    margin: 0;
    font-weight: 600;

    @include bp-small-medium('max') {
      margin-top: rem-calc(10);
    }
  }

  &__excerpt {
    h2 {
      margin-top: 0;
    }
  }
}
