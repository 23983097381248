/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero {
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: rem_calc(30);
}

.c-hero--imageless {
  background-color: $color-grey-light-medium;
  padding: rem-calc(50);
}

.c-hero__content {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);

  @include bp-medium {
    padding-left: rem-calc(50);
    padding-right: rem-calc(50);
  }

  .c-hero--imageless & {
    position: relative;
    z-index: 3;
    top: auto;
    transform: none;
  }
}

.c-hero__img {
  opacity: 0.4;
  margin-left: auto;
  margin-right: auto;
}

.c-hero__title {
  text-transform: uppercase;
  font-weight: bold;
}

.c-hero__meta {
  font-size: rem-calc(14);

  @include bp-medium {
    font-size: rem-calc(20);
  }

  @include bp-large {
    font-size: rem-calc(24);
  }
}
