/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

$utility-spacing: $global-spacing;

.u-margin {
  margin: $utility-spacing;
}

.u-margin-top {
  margin-top: $utility-spacing;
}

.u-margin-top--small {
  margin-top: $utility-spacing/2;
}

.u-margin-right {
  margin-right: $utility-spacing;
}

.u-margin-right-small {
  margin-right: ($utility-spacing / 2);
}

.u-margin-bottom {
  margin-bottom: $utility-spacing;
}

.u-margin-bottom-small {
  margin-bottom: ($utility-spacing / 2);
}

.u-margin-bottom-large {
  margin-bottom: ($utility-spacing * 2);
}

.u-margin-bottom-md {
  margin-bottom: rem_calc(36);
}

.u-margin-left {
  margin-left: $utility-spacing;
}

.u-no-margin {
  margin: 0 !important;
}

.u-no-margin-top {
  margin-top: 0 !important;
}

.u-no-margin-bottom {
  margin-bottom: 0 !important;
}

.u-margin-nbsp {
  margin-bottom: 3px;
}

.u-padding {
  padding: $utility-spacing;
}

.u-no-padding {
  padding: 0;
}

.u-padding-top {
  padding-top: $utility-spacing;
}

.u-padding-top--large {
  padding-top: $utility-spacing * 2;
}

.u-padding-right {
  padding-right: $utility-spacing;
}

.u-padding-bottom {
  padding-bottom: $utility-spacing;
}

.u-padding-left {
  padding-left: $utility-spacing;
}

.u-padding-left--small {
  padding-left: $utility-spacing/2;
}

@include bp-large('max') {
  .u-margin-bottom-up-md {
    margin-bottom: $utility-spacing;
  }
}
