.table-sortable { /* stylelint-disable-line selector-no-qualifying-type */

  .tablesorter-icon {
    background-size: cover !important;
    background-position: 50% 0;
    width: 10px !important;
  }

  .tablesorter-icon-default { /* stylelint-disable-line selector-no-qualifying-type */
    background-image: url('../assets/images/sort.svg');
  }

  @at-root .c-x5__table--header-white .tablesorter-icon {
    background-position: 50% 100%;
  }

  .tablesorter-icon-asc { /* stylelint-disable-line selector-no-qualifying-type */
    background-image: url('../assets/images/sort-asc.svg');
  }

  .tablesorter-icon-desc { /* stylelint-disable-line selector-no-qualifying-type */
    background-image: url('../assets/images/sort-desc.svg');
  }
}

.cform__loader {
  background-image: url('../assets/images/loader.svg');
}

.c-footer__logo,
.c-header__logo {
  /* stylelint-disable declaration-block-no-duplicate-properties */

  img {
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering: -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */ /* stylelint-disable-line property-no-vendor-prefix */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */ /* stylelint-disable-line property-no-vendor-prefix */
  }

  /* stylelint-enable */
}
