.c-galleries {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &--list {
    padding-top: rem_calc(30);

    @include bp-large('max') {
      padding-top: 0;
    }

    li {
      padding: rem-calc(5 10);
      border-bottom: 1px solid $color-grey-lighter;
    }
  }
}

.c-gallery {
  &__title {
    margin: 0;
    font-size: rem_calc(24);

    @include bp-xlarge-medium('max') {
      font-size: rem_calc(20);
    }

    @include bp-medium('max') {
      font-size: rem_calc(18);
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: $global-transition;

      &:hover {
        color: $color-link-alt;
      }
    }

    .c-galleries--list & {
      font-size: rem_calc(18);
    }
  }

  &__image {
    display: block;
    overflow: hidden;
  }

  img {
    transition: $global-transition;
  }

  &:hover {
    img {
      transform: scale(1.1) rotate(2deg);
    }
  }
}

.c-gallery__single {
  display: flex;
  flex-flow: row wrap;
  margin-left: - rem-calc(5);
  margin-right: - rem-calc(5);
  align-items: center;

  &-item {
    &-overlay {
      background-color: rgba(0, 0, 0, 0.4);
    }

    .icon {
      width: rem_calc(40);
      height: rem_calc(40);
      fill: $color-white;
    }
  }

  @for $i from 1 through 6 {
    // @if $i >= 4 {
    //   margin-left: - rem-calc(5);
    //   margin-right: - rem-calc(5);
    // }

    &--cols-#{$i} &-item {
      width: (100 / $i) * 1%;
      flex-basis: (100 / $i) * 1%;
      padding: rem-calc(5);

      @if $i < 4 {
        .icon {
          width: rem_calc(50);
          height: rem_calc(50);
        }
      }

      @include bp-xlarge-medium('max') {
        @if $i >= 4 {
          width: 25%;
          flex-basis: 25%;
        }
      }

      @include bp-large('max') {
        @if $i >= 3 {
          width: 33.33333%;
          flex-basis: 33.33333%;
        }
      }

      @include bp-medium('max') {
        @if $i >= 2 {
          width: 50%;
          flex-basis: 50%;
        }
      }

      @include bp-small('max') {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

.c-gallery__inner {
  .c-galleries--thumbs & {
    max-width: rem_calc(530);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem-calc(20);
    border: solid 1px $color-grey-lighter;

    @include bp-large('max') {
      max-width: rem_calc(450);
    }

    @include bp-medium('max') {
      max-width: rem_calc(350);
    }

    .c-gallery__title {
      padding: rem_calc(5);
      text-align: center;
    }
  }
}
